var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-offer": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c("div", { staticClass: "row row-leasing-offer" }, [
        _c(
          "div",
          { staticClass: "col col-12" },
          _vm._l(_vm.offerDetails, function (row, i) {
            return _c("div", { key: i, staticClass: "row row-border-line" }, [
              _c("div", { staticClass: "col col-xs-12 col-3" }, [
                _c("p", [_vm._v(_vm._s(row.label))]),
              ]),
              Array.isArray(row.text)
                ? _c(
                    "div",
                    { staticClass: "col col-xs-12 col-9" },
                    _vm._l(row.text, function (text, index) {
                      return _c("p", {
                        key: index,
                        domProps: { innerHTML: _vm._s(text) },
                      })
                    }),
                    0
                  )
                : _c("div", { staticClass: "col col-xs-12 col-9" }, [
                    _c("p", { domProps: { innerHTML: _vm._s(row.text) } }),
                  ]),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }