<template>
  <div class="page-offer">
    <SectionSelector
      v-for="(section, key) in sections"
      ref="sections"
      :enabled="section.enabled"
      :hash="section.hashAnchor"
      :variant="section.variant"
      :offer="offer"
      :data="section.data"
      :project="project"
      :multi-project="section.multi_project"
      :section="section.type"
      :order-no="section.order_no"
      :key="key"
      v-scroll="handleScroll"
    />
  </div>
</template>

<script>
import SectionSelector from "./SectionSelector";
export default {
  name: "PageOffer",
  components: { SectionSelector },

  data() {
    return {
      sections: [],
    };
  },
  watch: {
    offerSections: {
      deep: true,
      handler: function () {
        this.handleSectionParsing();
        this.updateActiveProject(this.project.slug);
      },
    },
  },
  async beforeMount() {
    const offerUUID = this.$route.params.offerId;
    const offerConstants = this.$store.getters.constants.offer;
    const projectConstants = this.$store.getters.constants.project;
    this.$store.dispatch(
      projectConstants.withNamespace(
        projectConstants.action.LOAD_LANDLORD_DETAILS
      )
    );
    this.$store.dispatch(
      offerConstants.withNamespace(offerConstants.action.LOAD_OFFER_DETAILS),
      offerUUID
    );
  },
  computed: {
    offerSections() {
      return this.$store.state.offer.sections;
    },
    sectionsData() {
      return this.$route.meta.page.sections.filter((s) =>
        this.offerSections.includes(s.id)
      );
    },
    offer() {
      return this.$store.state.offer.offer;
    },
    project() {
      if (!this.offer) return this.projects[0];
      return this.projects.find((p) => p.id === this.offer.deal.project_id);
    },
    projects() {
      return this.$store.state.project.projects;
    },
  },
  methods: {
    handleScroll(event, element) {
      const scrollY = window.scrollY + 100;
      document
        .querySelectorAll('a.navigation-link[href*="#"]')
        .forEach((link) => {
          link.classList.remove("active");
          // const section = document.getElementById(`${link.getAttribute('href').substring(1)}`).parentElement;
          const section = document.getElementById(
            `${link.href.split("#")[1]}`
          ).parentElement;
          if (
            section.offsetTop <= scrollY &&
            section.offsetTop + section.offsetHeight > scrollY
          ) {
            link.classList.add("active");
          }
        });
    },
    updateActiveProject(slug) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.CHANGE_ACTIVE_PROJECT
        ),
        slug
      );
    },
    handleSectionParsing() {
      this.sections = this.sectionsData.sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1;
        }
        if (a.order_no > b.order_no) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
.page-offer {
  .section {
    position: relative;
    .navigation-target {
      position: absolute;
      top: -6rem;
      @media only screen and (max-width: 767px) {
        top: -80px;
      }
    }
  }
}
</style>
