<template>
  <div v-if="!section || !enabled"></div>
  <SectionHomepage v-else-if="section === 'Homepage'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionOffer v-else-if="section === 'Offer'" :data="activeDataSet" :hash="hash" :order-no="orderNo" :offer="offer"/>
  <SectionSpecs v-else-if="section === 'Specs'" :data="activeDataSet" :hash="hash" :order-no="orderNo" :variant="variant" />
  <SectionLocation v-else-if="section === 'Location'"  :data="activeDataSet" :hash="hash" :order-no="orderNo" :variant="variant" />
  <SectionContact v-else-if="section === 'Contact'" :data="activeDataSet" :hash="hash" :order-no="orderNo" :variant="variant" />
</template>

<script>
import SectionContact from '../project/section/SectionContact';
import SectionLocation from '../project/section/SectionLocation';
import SectionSpecs from '../project/section/SectionSpecs';
import SectionOffer from './section/SectionOffer';
import SectionHomepage from './section/SectionHomepage';
export default {
  name: "SectionSelector",
  components: {
    SectionContact,
    SectionLocation,
    SectionSpecs,
    SectionOffer,
    SectionHomepage
  },
  props: {
    section: {
      required: true,
      type: String,
      default: "",
    },
    variant: {
      required: false,
      type: String,
      default: ""
    },
    multiProject: {
      required: false,
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Object | Array,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    enabled: {
      required: false,
      type: Boolean,
      default: false
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
    project: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },

    },
    offer: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    }
  },
  computed: {
    activeDataSet() {
      if (this.multiProject) {
        return this.data[this.project.slug];
      }
      return this.data;
    }
  }
}
</script>

<style scoped>

</style>