var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-homepage": true,
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c("div", { staticClass: "gradient" }),
      _c("div", { staticClass: "title-bottom" }, [
        _c("h1", [
          _vm._v("\n      Commercial Offer for:"),
          _c("br"),
          _vm._v("\n      " + _vm._s(_vm.activeProject.name) + "\n    "),
        ]),
        _c("p", { staticClass: "line-text" }, [
          _vm._v("\n      Welcome to "),
          _c("b", [_vm._v(_vm._s(_vm.activeProject.name))]),
        ]),
      ]),
      _c("div", {
        staticClass: "offer-image-background",
        style: {
          "background-image": "url(" + _vm.resourceUrl(_vm.backgroundUrl) + ")",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }