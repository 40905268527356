<template>
  <div
    :class="{
      section: true,
      'section-homepage': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="gradient"></div>
    <div class="title-bottom">
      <h1>
        Commercial Offer for:<br />
        {{ activeProject.name }}
      </h1>
      <p class="line-text">
        Welcome to <b>{{ activeProject.name }}</b>
      </p>
    </div>
    <div
      class="offer-image-background"
      :style="{ 'background-image': `url(${resourceUrl(backgroundUrl)})` }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "SectionHomepage",
  components: {},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {
          backgroundImage: "",
        };
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  computed: {
    backgroundUrl() {
      return this.data.backgroundImage;
    },
    activeProject() {
      return this.$store.getters.getActiveProjectData;
    },
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
};
</script>

<style lang="scss">
.section-homepage {
  position: relative;
  min-height: 60rem;
  @media only screen and (max-width: 767px) {
    min-height: 300px;
  }
  .offer-image-background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .navigation-target {
    position: absolute;
    top: 0;
    left: 0;
  }
  .gradient {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.4);
  }
  .title-bottom {
    position: absolute;
    bottom: 6.25rem;
    left: 5.625rem;
    z-index: 9;
    max-width: 60rem;
    @media only screen and (max-width: 767px) {
      max-width: 100%;
      bottom: 20px;
      left: 0;
      padding: 0 20px;
    }
    .line-text {
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: $light;
      text-transform: uppercase;
      position: relative;
      padding-left: 6.25rem;
      margin-bottom: 1.875rem;
      color: $white;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
        padding: 25px 0 0 0;
        margin-bottom: 20px;
      }
      b {
        font-size: 1.125rem;
        line-height: 1.375rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        width: 5.688rem;
        height: 1px;
        background: $white;
        top: 50%;
        left: 0;
        margin-top: -0.5px;
        @media only screen and (max-width: 767px) {
          top: 0;
          margin-top: 0;
          width: 91px;
        }
      }
    }
    h1 {
      font-size: 3.938rem;
      line-height: 4.875rem;
      text-transform: uppercase;
      font-weight: $regular;
      margin-bottom: 1.25rem;
      color: $white;
      @media only screen and (max-width: 767px) {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
    .link-atom {
      padding: 0.75rem 1.563rem;
      margin-bottom: 3.125rem;
      @media only screen and (max-width: 767px) {
        padding: 8px 20px;
        margin-bottom: 30px;
      }
      .icon {
        font-size: 1.25rem !important;
        margin-left: 0.625rem !important;
        @media only screen and (max-width: 767px) {
          font-size: 12px !important;
          margin-left: 5px !important;
        }
      }
    }
  }
  .scroll-element-desktop {
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: $white;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      display: none;
    }
    .icon-scroll-down {
      display: inline-block;
      vertical-align: middle;
      width: 1.688rem;
      height: 1.688rem;
      margin-right: 0.625rem;
      line-height: 1.6rem;
      text-align: center;
      border: 0.125rem solid rgba(255, 255, 255, 0.1);
      border-radius: 1.688rem;
      .icon {
        font-size: 1.063rem !important;
      }
    }
  }
  .scroll-element-mobile {
    display: none;
    @media only screen and (max-width: 767px) {
      position: absolute;
      left: 50%;
      border: 1px solid $white;
      z-index: 9;
      cursor: pointer;
      display: flex;
      align-items: center;
      bottom: 30px;
      width: 46px;
      height: 46px;
      border-radius: 46px;
      margin-left: -23px;
      .icon {
        font-size: 32px !important;
      }
    }
  }
}
</style>
