var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.section || !_vm.enabled
    ? _c("div")
    : _vm.section === "Homepage"
    ? _c("SectionHomepage", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
        },
      })
    : _vm.section === "Offer"
    ? _c("SectionOffer", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          offer: _vm.offer,
        },
      })
    : _vm.section === "Specs"
    ? _c("SectionSpecs", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant,
        },
      })
    : _vm.section === "Location"
    ? _c("SectionLocation", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant,
        },
      })
    : _vm.section === "Contact"
    ? _c("SectionContact", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }