<template>
  <div :class="{section: true, 'section-offer': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="row row-leasing-offer">
      <div class="col col-12">
        <div class="row row-border-line" v-for="(row, i) in offerDetails" :key="i">
          <div class="col col-xs-12 col-3">
              <p>{{row.label}}</p>
          </div>
          <div class="col col-xs-12 col-9" v-if="Array.isArray(row.text)">
              <p v-for="(text, index) in row.text" :key="index" v-html="text"></p>
          </div>
          <div class="col col-xs-12 col-9" v-else>
            <p v-html="row.text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { textTemplate } from '../../../helpers/util'
export default {
  name: "SectionOffer",
  props: {
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
    offer: {
      required: false,
      type: Object,
      default: function () {
        return [];
      },
    },
    data: {
      required: true,
      type: Array,
      default: function () {
        return []
      },
    }
  },
  computed: {
    offerDetails() {
      if (!this.offer) return [];
      const offerDetails = [];
      const offerFields = [];
      Object.keys(this.offer).filter(k => [
          'surface',
          'floor',
          'parking_spaces',
          'storage_spaces',
          'lease_term',
          'base_rent_office',
          'incentive_free_months',
          'base_rent_parking_interior',
          'base_rent_parking_exterior',
          'handover_date',
          'floorNumbersContainingAvailableSpaces'
      ].includes(k)).forEach((key) => {
        let type;
        switch(key) {
          case 'handover_date':
            type = 'Date';
            break;
          case 'floorNumbersContainingAvailableSpaces':
            type = 'Array';
            break;
          default:
            type = 'Text';
            break;
        }
        offerFields.push({
          literal: key,
          value: this.offer[key],
          type
        })
      })
      this.$props.data.forEach((detail) => {
        let text = '';
        if (Array.isArray(detail.templateText)) {
          text = [];
          detail.templateText.forEach((t) => {
            text.push(textTemplate.replace(t, offerFields));
          });
        } else {
          text = textTemplate.replace(detail.templateText, offerFields)
        }
        const obj = {
          label: detail.label,
          text
        }
        offerDetails.push(obj);
      });
      return offerDetails;
    }
  }
}
</script>

<style lang="scss">
  .row-leasing-offer {
    width: 90rem;
    margin: 3.125rem auto;
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 0 20px;
      margin: 30px 0;
    }
    .row-border-line {
      margin: -2px 0 0;
      &:first-child {
        .col-3 {
          border-top-color: $main-color;
        }
      }
      .col-3 {
        background: $main-color;
        padding: 1.25rem 0.938rem;
        border: 2px solid $main-color;
        border-top-color: $white;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 767px) {
          padding: 20px 15px;
        }
        p {
          font-size: 1rem;
          line-height: 1.375rem;
          color: $white;
          font-weight: $bold;
          text-align: center;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
      .col-9 {
        border: 2px solid $main-color;
        padding: 1.25rem 0.938rem;
        justify-content: center;
        @media only screen and (max-width: 767px) {
          padding: 20px 15px;
        }
        p {
          font-size: 1rem;
          line-height: 1.375rem;
          margin-bottom: 0.313rem;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 5px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>